.step {
	position: relative;
	counter-increment: big-step 1;
}

.step > div {
	margin-bottom: calc(var(--big-margin) * 3);
}

.step h3 {
	display: flex;
	position: relative;
	color: var(--dark-blue);
	height: 100%;
	align-items: center;
}

.step h3:before {
    content: counter(big-step);
    font-family: var(--Bungee-inline-text-stack);
    font-weight: normal;
    font-size: 2.4em;
    display: flex;
    align-items: center;
    flex: 0 0 0.75em;
}

.step p {
	margin:  0;
	margin-bottom: 1em;
}

.step:nth-child(3n - 1) h3 {
	color: var(--mid-red);
}

.step:nth-child(3n) h3 {
	color: var(--mid-blue);
}


@media all and (min-width: 1200px) {

	.step h3:before {
	    font-size: 0.7em;
	    height: 1.5em;
	    flex: 0 0 2em;
	    background-image: url('~./../assets/dark-blue-heart.svg');
	    background-size: contain;
  		background-repeat: no-repeat;
  		background-position: 50% 54%;
  		margin-left: -1em;
  		display: grid;
  		text-align: center;
  		color: var(--bg);
	}

	.step:nth-child(3n - 1) h3:before {
  		background-image: url('~./../assets/mid-red-heart.svg');
	}

	.step:nth-child(3n) h3:before {
  		background-image: url('~./../assets/mid-blue-heart.svg');
	}
	

}
