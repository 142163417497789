@import url('https://fonts.googleapis.com/css2?family=Bungee&family=Bungee+Inline&family=Work+Sans&display=swap');

:root {
  --mini-margin: 8px;
  --small-margin:  calc(var(--mini-margin) * 2);
  --big-margin: calc(var(--mini-margin) * 3);
  --dark-blue: #3a6fbf;
  --mid-blue: #5392CC;
  --dark-red: #D85858;
  --mid-red: #DA7A7A;
  --bg: #FAF2E6;
  --Bungee-text-stack: "Bungee", "Bungee Inline", sans-serif;
  --Bungee-inline-text-stack: "Bungee Inline", "Bungee", sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Work Sans', 'Helvetica Neue', 'Helvetica', 'Arial',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg);
  font-size: min(calc(3px + 0.825vw), 16px);
  overflow-y: auto;
  overflow-x: hidden;
}

code {
  font-family: Consolas, source-code-pro, Menlo, Monaco, 'Courier New',
    monospace;
  font-size: 85%;
  background-color: #F2D6D6;
  border-radius: 3px;
  padding: 0 3px;
}

a {
  color: #bd2929;
}

ul {
  padding-inline-start: var(--big-margin);
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; 
  -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;  
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}


@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
