.contentWrapper {
  margin:  0 auto var(--big-margin) auto;
  max-width: 1350px;
}

.content {
  margin: max(2.25%, var(--small-margin));
}

.content > * > *:not(.sr-only):not(.heroVideo) {
  margin-top:  var(--small-margin);
  margin-bottom: 0px;
}

.fullWidth {
  width:  100%;
}


.bold {
  font-weight: bold;
}

main {
  width:  80%;
  margin: 7em auto;  
  position: relative;
  counter-reset: big-step 0;
}

h1, h2, h3 {
  font-family: var(--Bungee-text-stack);
  font-weight: 500;
}

h2 {
  font-size: 4.25em;
  line-height: 95%;
  color: var(--dark-blue);
}

h3 {
  font-size: 4em;
  line-height: 98%;
}

p {
  font-size: 2em;
  font-family: 'Work Sans';
}

li {
  font-size: 1.85em;
  margin-bottom: 0.5em;
  font-family: 'Work Sans';
}

ul {
  margin-bottom: var(--big-margin);
}

.video {
  aspect-ratio: 16 / 9;
  width: 100%;
  margin-bottom: var(--big-margin);
}

#player {
  width: 100%;
  height: 100%;
}

.heroVideo {
  aspect-ratio: 16 / 9;
  width: 100%;
  background: linear-gradient(180deg, #9CC5EB 0%, rgba(156, 197, 235, 0) 100%);
  position: relative;
  margin:  2em 0;
}

.heroVideo button {
  position: absolute;
  top: 50%;
  transform:  translate(0px, -50%);
  left: 15%;
  background-color: var(--dark-red);
  display: inline-block;
  border: none;
  padding: min(1em, 1.25vw) min(4em, 5vw);
  border-radius: 10px;
  margin: 0;
  text-decoration: none;
  color: var(--bg);
  font-family: sans-serif;
  cursor: pointer;
  text-align: center;
  transition: transform 150ms ease, box-shadow 150ms, opacity 1s, left 1s;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: 8px 8px var(--mid-red);
  opacity: 1;

}

.heroVideo button.hide {
  left: -100%;
  opacity: 0;
  transition: left 1s, opacity 1s;
}

.heroVideo button:active {
  box-shadow: 0px 0px var(--mid-red);
  transform: translate(8px, calc(-50% + 8px));
  transition: transform 150ms ease, box-shadow 150ms;
}

.heroVideo button img {
  width: 4.5vw;
  height: auto;
}

.heroVideo button span {
  display: block;
  font-size: 1.75em;
}

.navList {
  font-family: var(--Bungee-text-stack);
  font-size: 3em;
  line-height: 95%;
  counter-reset: small-step 0;
  position: relative;
}

.navList span {
  counter-increment: small-step 1;
  z-index: 3;
  text-shadow: 0px 0px var(--dark-red);
  transition: text-shadow 0.25s;
}

.navList span:hover {
  text-shadow: 1px 1px var(--dark-red);
  transition: text-shadow 0.25s;
}


.navList span a {
  position: relative;
  z-index: 3;
  margin-right: var(--small-margin);
}

.navList span a:hover {
  z-index: 1;
}


.navList span, .navList span a {
  color: var(--dark-blue);
  text-decoration: none;
}

.navList span:before {
  content: counter(small-step);
  font-size: 0.45em;
  display: inline-block;
  vertical-align: bottom;
  width: 1.9em;
  text-align: center;
  background-image: url('~./assets/dark-blue-heart.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 54%;
  margin: 0 var(--small-margin) 0 0;
  color: var(--bg);
  background-color: var(--bg);
  z-index: 5;
  position: relative;
}


.navList span:after {
  background-image: url('~./assets/sparkle.svg');
  background-size: cover;
  content:  '';
  position: absolute;
  top:  0px;
  left:  0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
  opacity: 0;
}

.navList span:hover:after {
  left: 2%;
  top:  -5%;
  opacity: 1;
  transition: left 3s, opacity 1s, top  3s;
}

.navList span:nth-last-child(-n+2):hover:after {
  top:  5%;
}


.navList span:nth-child(3n - 1), .navList span:nth-child(3n - 1) a {
  color: var(--mid-red);
}

.navList span:nth-child(3n), .navList span:nth-child(3n) a {
  color: var(--mid-blue);
}

.navList span:nth-child(3n - 1):before {
  background-image: url('~./assets/mid-red-heart.svg');
}

.navList span:nth-child(3n):before {
  background-image: url('~./assets/mid-blue-heart.svg');
}

.overlay {
  width: 100%;
  height: 100%;
  position: relative;
}

.frameOverlay {
  position: absolute;
  width: 104.5%;
  height: 104.5%;
  left: -2.25%;
  top: -2.25%;
  pointer-events: none;
}

.drawing {
  position: absolute;
  bottom: 0.35%;
  right: 0px;
  width: 67%;
  pointer-events: none;
  opacity: 1;
  transition: right 1s, opacity 1s;
}

.drawing.hide {
  right: -100%;
  opacity: 0;
  transition: right 1s, opacity 1s;
}

.nameBanner {
  width: 100%;
  display: flex;
  padding-bottom: var(--big-margin);
}

.nameBanner div {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}

.nameBanner div > img {
  width: 100%;
  height: 85%;
  object-fit: cover;
}

.nameBanner p {
  font-family: var(--Bungee-text-stack);
  margin: 0 1em;
  color: var(--mid-red);
  display: block;
  white-space: nowrap;
}

@media all and (max-width: 1200px) {
  .navList span:before {
    background-image: none;
    font-size: 1em;
    width: 0.225em;
    color: var(--dark-blue);
  }

  .navList span:nth-child(3n - 1):before {
    background-image: none;
    color: var(--mid-red);
  }

  .navList span:nth-child(3n):before {
    background-image: none;
    color: var(--mid-blue);
  }

  main {
    width:  100%;
    margin: 7em 0 0 0;  
  }

  h2 {
    hyphens: manual;
  }

}

@media all and (max-width: 950px) {
  .heroVideo {
    margin-top:  3.25em;
  }

  .nameBanner {
    padding-bottom: 0px;
  }

  .nameBanner p {
    font-size: 2.5em;
    margin: 0 0.75em;
  }

  .nameBanner div > img {
    height: 65%;
  }

  .heroVideo button span {
    font-size: 1em;
  }

  .heroVideo button {
      box-shadow: 4px 4px var(--mid-red);
  }

  .heroVideo button:active {
    transform: translate(4px, calc(-50% + 4px));
  }
}

@media all and (max-width: 500px) {
  h2 {
    font-size: 3.5em;
  }

  .content {
    margin: var(--mini-margin);
  }

  .heroVideo {
    margin-top:  3.25em;
  }

  .heroVideo button span {
    font-size: 0.75em;
  }

  nav {
    margin-bottom: 75px;
  }

  p {
    font-size: 2.75em;
  }

  li {
     font-size: 2.5em;
  }


}